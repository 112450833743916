import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewCompanyComponent } from './new-company/new-company.component';
import { SignInComponent } from './components/signin/signin.component';
import { MsalAuthGuard  } from './shared/services/msal-auth.guard';

const routes: Routes = [
  {
    path: 'signin',
    component: SignInComponent
  },
  {
    path: '',
    redirectTo: 'companies',
    pathMatch: 'full'
  },
  {
    path: 'companies',
    loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
    data: {title: 'Companies'},
    canActivate: [MsalAuthGuard ]
  },
  {
    path: '**',
    redirectTo: '/companies',
    data: { title: 'Not Found' },
  },
  {
    path: 'new-company',
    component: NewCompanyComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }