import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { DataService } from 'src/app/shared/services';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.less']
})
export class NewCompanyComponent implements OnInit {
  
  isLoading = true;
  kudosPerDay: number[];

  companyForm: FormGroup = this.formBuilder.group({
    id: [0],
    name: ['', [Validators.required]],
    createdOn: [],
    isActive: [true],
    kudosPerDay: [0, [Validators.required, Validators.min(0), Validators.max(100)],],
    allowAnonymousKudos: [true, [Validators.required]],
    createdBy: ['SystemUser'],
  });

  constructor(

    private alerts: AlertsService, 
    private dataService: DataService, 
    private formBuilder: FormBuilder, 
    private router: Router

  ){ }

  afterSuccess(){
    this.router.navigate(['./companies']);
  }
  
  ngAfterContentInit() {
    this.isLoading = false;
  }

  ngOnInit(): void {
    
  }

  submit() {
    this.companyForm.controls['createdOn'].setValue(new Date());
      this.dataService.post(`${environment.apiUrl}companies`, this.companyForm.value)
        .then(() => this.alerts.showSuccess())
        .then(() => this.afterSuccess())
        .catch((err) => this.alerts.showError(err));
  }

}
