import { Component } from '@angular/core';
import { AuthService} from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent {
  constructor(
    private authService: AuthService) { }

    logout() {
      this.authService.logout();
    }
}