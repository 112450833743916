<div class="container-fluid">
    <h1>New Company</h1>
    <form [formGroup]="companyForm" (ngSubmit)="submit()">
        <div *ngIf="!isLoading">
            <div class="form-group">
                <label for="name">Company Name: </label>
                <input minlength="3" maxlength="100" required type="text" class="form-control" formControlName="name" />
                <label for="kudosPerDay">Kudos Per Day: </label>
                <input
                    [ngClass]="(companyForm.controls.kudosPerDay.errors?.max||companyForm.controls.kudosPerDay.errors?.min)?'is-invalid':'' "
                    class="form-control" required type="number" formControlName="kudosPerDay" min="0" max="100"
                    required>
                <div *ngIf="companyForm.controls.kudosPerDay.errors?.max" class="invalid-feedback">
                    The maximum value is 100.
                </div>
                <div *ngIf="companyForm.controls.kudosPerDay.errors?.min" class="invalid-feedback">
                    The minimum value is 0.
                </div>
            </div>
            <div class="form-check form-group">
                <input mdbCheckbox type="checkbox" class="form-check-input" formControlName="allowAnonymousKudos" />
                <label class="form-check-label" for="allowAnonymousKudos">Allow Anonymous Kudos</label>
            </div>
            <div class="form-actions no-color">
                <button class="btn btn-primary" type="submit" [disabled]="companyForm.invalid">
                    Save
                </button>
            </div>
        </div>
    </form>
</div>
