import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  private routesSubscription: Subscription;
  showHeader = true;
  constructor(
    private router: Router,
    private titleService: Title,
    private authService: AuthService,   
  ){ }

  ngOnDestroy(){
    this.routesSubscription.unsubscribe();
  }

  ngOnInit(): void{
   this.routesSubscription = 
   this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }

        this.showHeader = !this.shouldHideHeader(route.snapshot.routeConfig?.path);

        return routeTitle;
      })).subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(`EWC | Companies - FactorK Software S.A, ${title}`);
      }
    });
    this.authService.setupAccountStorageEvents();
    this.authService.setupInProgressListener();
  }

  private shouldHideHeader(routePath: string): boolean {
    return routePath === 'signin';
  }
}